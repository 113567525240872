import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Homepage from "./Components/Homepage/Homepage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import Contact from "./Components/Contact/Contact";
import Tafta from "./Components/Projects/Tafta";
import Vlae from "./Components/Projects/Vlae";
import DebarMaalo from "./Components/Projects/DebarMaalo";
import Navigation from "./Components/Navbar/Navigation";
import Footer from "./Components/Footer/Footer";
import MainProject from "./Components/Projects/MainProject";
import Gallery from "./Components/Gallery/Gallery";

//sass
import "../src/styles/app.scss";
import Vlae2 from "./Components/Projects/Vlae2/Vlae2";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={Homepage}></Route>
          <Route path="/aboutus" exact component={AboutUs}></Route>
          <Route path="/contact" exact component={Contact}></Route>
          <Route path="/gallery" exact component={Gallery}></Route>
          <Route path="/projects" exact component={MainProject}></Route>
          <Route path="/projects/tafta" exact component={Tafta}></Route>
          <Route path="/projects/vlae" exact component={Vlae}></Route>
          <Route path="/projects/debarmaalo" exact component={DebarMaalo}></Route>
          <Route path="/projects/vlae2" exact component={Vlae2}></Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
