import React, { useState } from "react";

import MapDialog from "../../MapDialog/MapDialog";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import front1 from "../../../img/vlae2/front1.jpg";
import front2 from "../../../img/vlae2/front2.jpg";
import back1 from "../../../img/vlae2/back1.jpg";
import back2 from "../../../img/vlae2/back2.jpg";
import stan1 from "../../../img/vlae2/stan1.png";
import stan4 from "../../../img/vlae2/stan4.png";
import stan5 from "../../../img/vlae2/stan5.png";

const imgsArr = [front1, front2, back1, back2, stan1, stan4, stan5];

function Vlae2() {
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const lat = 42.0110333;
  const long = 21.373122;

  return (
    <>
      <MapDialog
        open={openLocationModal}
        setOpenDialog={setOpenLocationModal}
        lat={lat}
        long={long}
        zoom={15}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
          marginBottom: "25px",
        }}
      >
        <h2 style={{ color: "#ff6e01" }}>Влае - ул.Фјодор Достоевски бр. 57</h2>
        <span
          style={{ color: "#ff6e01", marginTop: "15px", marginBottom: "15px" }}
        >
          Становите се во продажба
        </span>
        <span
          onClick={() => {
            setOpenLocationModal(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            color: "#ff6e01",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Клик за локација на мапа{" "}
          <LocationOnIcon style={{ marginBottom: 3}} />
        </span>

        {imgsArr.map((img) => {
          return (
            <img
              style={{ width: "1100px", marginTop: "25px" }}
              className="img-fluid"
              src={img}
              alt="mcc-counstruction vlae"
            />
          );
        })}
      </div>
    </>
  );
}

export default Vlae2;
