import React from "react";
import "./Vlae.css";
import { Col, Container, Row, Carousel } from "react-bootstrap";

//img imports

//final
import final1 from "../../img/vlae/final1.jpg";
import final2 from "../../img/vlae/final2.jpg";
import final3 from "../../img/vlae/final3.jpg";
import final4 from "../../img/vlae/final4.jpg";
import final5 from "../../img/vlae/final5.jpg";
import final6 from "../../img/vlae/final6.jpg";
import final7 from "../../img/vlae/final7.jpg";
import final8 from "../../img/vlae/final8.jpg";
import final9 from "../../img/vlae/final9.jpg";
import final10 from "../../img/vlae/final10.jpg";
import final11 from "../../img/vlae/final11.jpg";
import final12 from "../../img/vlae/final12.jpg";
import final13 from "../../img/vlae/final13.jpg";

//videos
import video1 from "../../img/vlae/video1.MOV";
import video2 from "../../img/vlae/video2.MOV";

import vlae1 from "../../img/vlae/vlae1.jpg";
import vlae2 from "../../img/vlae/vlae2.jpg";
import vlae3 from "../../img/vlae/vlae3.jpg";
import vlae4 from "../../img/vlae/vlae4.jpg";
import vlae5 from "../../img/vlae/vlae5.jpg";
import vlae6 from "../../img/vlae/vlae6.jpg";

// import introVlaeImg from "../../img/vlae/smartHomeVlae.jpg";

//img renders
// import stan2 from "../../img/vlae/stan2.jpg";
// import stan3 from "../../img/vlae/stan3.jpg";
// import stan4 from "../../img/vlae/stan4.jpg";
// import stan5 from "../../img/vlae/stan5.jpg";
// import stan6 from "../../img/vlae/stan6.jpg";

//img outside
import prostor from "../../img/vlae/prostor.jpg";
import zgrada1 from "../../img/vlae/zgrada1.jpg";
import zgradaStrana2 from "../../img/vlae/zgradaStrana2.jpg";
import zgrada2 from "../../img/vlae/zgrada2.jpg";
import zgradaStrana from "../../img/vlae/zgradaStrana.jpg";
import nadvorMerac from "../../img/vlae/nadvorMerac.jpg";
import vnatre1 from "../../img/vlae/vnatre1.jpg";
import vnatre2 from "../../img/vlae/vnatre2.jpg";
import vnatreMajstori from "../../img/vlae/vnatreMajstori.jpg";
import vnatre3 from "../../img/vlae/vnatre3.jpg";
import vnatre4 from "../../img/vlae/vnatre4.jpg";
import vnatre5 from "../../img/vlae/vnatre5.jpg";
import vnatre6 from "../../img/vlae/vnatre6.jpg";

import zgrada3 from "../../img/vlae/zgrada3.jpg";
import zgrada4 from "../../img/vlae/zgrada4.jpg";
import zgrada5 from "../../img/vlae/zgrada5.jpg";

const carouselImgs = [vlae1, vlae2, vlae3, vlae4, vlae5, vlae6];

function Vlae(props) {
  return (
    <div className="secondProject pt-5">
      <div className="vlaeTitle pb-4">
        <h2>Влае - ул.Бела Краина бр. 1б</h2>
        <span>Становите се во продажба</span>
      </div>
      <Container>
        <Row className="introVlae p-3">
          <Col md={12}>
            <p>
              Мини зграда на прекрасна локација во Влае. Контролирајте гo вашиот
              дом преку вашиот телефон. Елаборат за енергетски ефикасен дом и
              сеизмичка стабилност на објектот одобрен од "Изиис"-Скопје.
              Топлински пумпи со фенкојлери за најеконимично ладење и греење на
              собите. Германски 6 коморни "Salamander" профили со трослојно 4
              сезонско стакло и германски оков "Roto". Италијански собни врати
              "Di Porte". Првокласен дабов трослоен паркет во собите. Првоклсани
              гранитни плочки во бањата,кујната и терасите.
            </p>
          </Col>
          {/* <Col md={6}>
            <img src={introVlaeImg} alt=""></img>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <div className="carousel pb-4">
              <Carousel>
                {carouselImgs.map((item) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={item}
                        alt="mcc-counstruction"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="renderCol">
            <div className="video-wrap">
              <video src={video1} className="video-vlae" controls="controls" />
              <video src={video2} className="video-vlae" controls="controls" />
            </div>
            <div>
              <img src={final13} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final6} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final7} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final8} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final9} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final10} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final12} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final1} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final2} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final3} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={final4} className="img-fluid" alt="" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={final5} style={{ width: "50%" }} alt="" />
              <img src={final11} style={{ width: "50%" }} alt="" />
            </div>
            <div>
              <img src={prostor} className="img-fluid" alt="" />
            </div>
            <div>
              <img src={zgrada1} className="img-fluid" alt="" />
            </div>
            <div className="lastImages">
              <div>
                <img src={zgradaStrana2} alt="" />
              </div>
              <div>
                <img src={zgrada2} alt="" />
              </div>
              <div>
                <img src={zgradaStrana} alt="" />
              </div>
              <div>
                <img src={nadvorMerac} alt="" />
              </div>
              <div>
                <img src={vnatre1} alt="" />
              </div>
              <div>
                <img src={vnatre2} alt="" />
              </div>
              <div>
                <img src={vnatreMajstori} alt="" />
              </div>
              <div>
                <img src={vnatre3} alt="" />
              </div>
              <div>
                <img src={vnatre4} alt="" />
              </div>
              <div>
                <img src={vnatre5} alt="" />
              </div>
              <div>
                <img src={vnatre6} alt="" />
              </div>
              <div>
                <img src={zgrada3} alt="" />
              </div>
              <div>
                <img src={zgrada4} alt="" />
              </div>
              <div>
                <img src={zgrada5} alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Vlae;
